const contacto = () => {
	document.addEventListener("DOMContentLoaded", function () {
		var defaultForm = document.querySelector("#formaContacto");
		if (defaultForm) {
			var nombre = defaultForm.querySelector("#nombre");
			var email = defaultForm.querySelector("#email");
			var tel = defaultForm.querySelector("#tel");

			defaultForm.addEventListener("submit", function (e) {
				if (
					nombre.value === "" ||
					nombre.value === null ||
					nombre.value === false
				) {
					e.preventDefault();
					nombre.classList.add("is-invalid");
				} else {
					nombre.classList.remove("is-invalid");
				}

				if (
					email.value === "" ||
					email.value === null ||
					email.value === false
				) {
					e.preventDefault();
					email.classList.add("is-invalid");
				} else {
					email.classList.remove("is-invalid");
				}

				if (tel.value === "" || tel.value === null || tel.value === false) {
					e.preventDefault();
					tel.classList.add("is-invalid");
				} else {
					tel.classList.remove("is-invalid");
				}
			});
		}
	});
};

export default contacto;
