import AOS from "aos";
import Swiper from "swiper/bundle";
import { Modal } from "bootstrap";
import MagicGrid from "magic-grid";
import contacto from "./contacto";

/*
-------------------------------
Animaciones scroll
-------------------------------
*/
AOS.init();

var scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

/*
-------------------------------
Banner video
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	const vidbanner = document.querySelector("#videoBanner");
	vidbanner.play();
});

/*
-------------------------------
Servicios
-------------------------------
*/

// Modal
document.addEventListener("DOMContentLoaded", function () {
	const modalTemplate = document.getElementById("modalTemplate");
	const btnsModals = document.querySelectorAll(".btn-modal");
	let modalTitle = document.querySelector("#modalTemplateLabel");
	let modalContainer = document.querySelector("#modalContainer");

	btnsModals.forEach(function (item) {
		const id = item.dataset.modalServicio;
		const titulo = item.dataset.modalTitulo;
		item.addEventListener("click", function (e) {
			e.preventDefault();
			const modalContenido = document.querySelector(
				`.modal-block[data-modalid='${id}']`
			);
			modalContainer.innerHTML = modalContenido.innerHTML;
			modalTitle.innerHTML = titulo;
		});
	});

	// Corre el swipper al abrir el modal
	modalTemplate.addEventListener("shown.bs.modal", function (event) {
		const swiper = new Swiper("#modalTemplate .swiper-container", {
			lazy: true,
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev",
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: true,
			},
			autoHeight: true,
		});
	});
});

/*
-------------------------------
Portafolio grid
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	let magicGrid = new MagicGrid({
		container: ".portafolio-grid", // Required. Can be a class, id, or an HTMLElement.
		animate: true,
		gutter: 30,
		static: true,
		useMin: true,
	});
	magicGrid.listen();
});

/*
-------------------------------
Contacto
-------------------------------
*/
contacto();
